body {
  margin: 0;
  background-image: url(/src/img/img-bg.jpg);
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}