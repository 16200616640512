.title {
    margin: auto;
    margin-top: 5rem;
    text-align: center;
    padding: 50px;
    font-family: 'Arial', sans-serif;
    color: #f0f3f7;
}

.page-one {

    display: flex;
    justify-content: center;
    margin-top: 5%;

}

.footer {
    color: beige;
    text-align: center;
    justify-content: center;
    margin-top: 25% !important;
    cursor: pointer;
}